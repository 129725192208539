import { useParams } from "react-router-dom";

import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import { Button, Grid, Typography, Box } from "@mui/material";
import GroupsSelector from "../statistics/GroupsSelector";
import { useTranslation } from "react-i18next";

import RolesSelector from "./RolesSelector";
import { useDebouncedCallback } from "use-debounce";
import { setMessage } from "../../redux/slices/snackbarSlice";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (i == 0 || i == 1) {
          return (
            <Grid key={element.id} alignItems="center" item xs={6}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const UserGroupShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [getUserGroup] = store.useLazyGetUserGroupShowQuery();
  const [updateUsergroup, { isSuccess }] = store.useUpdateUserGroupMutation();
  const userGroup = useSelector((state) => state?.userGroupSlice?.userGroup);
  const { t } = useTranslation();
  const schema = {
    title: "New user",
    type: "object",
    properties: {
      name: {
        $id: "4",
        type: "string",
        title: t("name"),
        default: userGroup?.name,
      },
      roles: {
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
        default: userGroup?.roles,
      },
      description: {
        $id: "5",
        type: "string",
        title: t("description"),
        default: userGroup?.description,
      },
    },
    required: ["name", "description"],
  };
  const fields = {
    serverSearch: RolesSelector,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    roles: {
      "ui:field": "serverSearch",
    },
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
  };
  useEffect(() => {
    getUserGroup(id);
  }, [id]);
  useEffect(() => {
    console.log("usrgrp", userGroup);
  }, [userGroup]);
  const onSubmitClick = () => {
    updateUsergroup({ id: id, attributes: { ...formData } });
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("update_success")));
      getUserGroup(id);
    }
  }, [isSuccess]);
  useEffect(() => {
    console.log("pennishuivvrot", formData);
  }, [formData]);
  return (
    <>
      {userGroup && (
        <Box>
          <Typography
            sx={{ mt: 2, mb: 4 }}
            color={theme.palette.primary.main}
            variant="h2"
          >
            {userGroup?.name}
          </Typography>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            fields={fields}
            formData={formData}
            onSubmit={onSubmitClick}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            validator={validator}
            showErrorList={false}
            noHtml5Validate
          />
        </Box>
      )}
    </>
  );
};
export default UserGroupShow;
