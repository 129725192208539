import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";

export const UserGroupApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getUserGroups: build.query({
        query: (params) => {
          return `v1/user_group?${prepareParams(params)}`;
        },
      }),
      getUserGroupShow: build.query({
        query: (id) => {
          return `v1/user_group/${id}`;
        },
      }),

      createUserGroup: build.mutation({
        query: (attributes) => ({
          url: `v1/user_group`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user_group")),
        }),
      }),
      updateUserGroup: build.mutation({
        query: ({ id, attributes }) => ({
          url: `/v1/user_group/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id, "user_group")),
        }),
      }),
      deleteUserGroup: build.mutation({
        query: (id) => ({
          url: `/v1/user_group/${id}`,
          method: "DELETE",
        }),
      }),
      blockGroupAssociate: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/altior/device/${id}/user_group`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user_group")),
        }),
      }),
      getBlockGroups: build.query({
        query: ({ id, params }) => {
          return `v1/altior/device/${id}/user_group?${prepareParams(params)}`;
        },
      }),
      deleteBlockGroup: build.mutation({
        query: ({ id, groupID }) => ({
          url: `v1/altior/device/${id}/user_group/${groupID}`,
          method: "DELETE",
        }),
      }),

      userGroupAssociate: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/user/${id}/user_group`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user_group")),
        }),
      }),
      getIndexUserGroups: build.query({
        query: ({ id, params }) => {
          return `v1/user/${id}/user_group?${prepareParams(params)}`;
        },
        transformResponse: (response) => {
          const transformedResponse = response?.data?.map((item) => ({
            ...item,
            device: response?.included.find(
              (i) => i.id == item?.attributes?.user_group_id
            ),
          }));
          return {
            data: transformedResponse,
            count: response?.meta?.count,
          };
        },
      }),
      deleteUsersGroup: build.mutation({
        query: ({ id, groupID }) => ({
          url: `v1/user/${id}/user_group/${groupID}`,
          method: "DELETE",
        }),
      }),
    }),
  });
