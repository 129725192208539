import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearImage, fetchImage } from "../../../redux/slices/getImageSlice";

const DigitalTwinImage = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.getImageSlice.image);
  const { t } = useTranslation();
  const imageId = props?.schema?.imageId;

  useEffect(() => {
    if (imageId) {
      dispatch(fetchImage(`${window.IMAGE_URL}/api/v1/image/${imageId}`));
    } else {
      dispatch(clearImage());
    }
  }, [imageId]);

  return (
    <>
      {data && (
        <Box
          sx={{
            height: 148,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #ccc", // Optional: to give a border around the image
            overflow: "hidden", // Hide overflow to prevent stretching issues
            borderRadius: "4px", // Add border-radius to the container for consistency
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
              display: "block",
              borderRadius: "4px", // Apply border-radius to the image
            }}
            component="img"
            src={`data:image/png;base64,${data}`}
            alt={t("fetchedImageAltText", "Fetched Image")} // Alt text for accessibility
          />
        </Box>
      )}
    </>
  );
};

export default DigitalTwinImage;
