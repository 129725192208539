import { useState, useEffect } from "react";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { Typography, useMediaQuery } from "@mui/material";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import Tooltip from "@mui/material/Tooltip";
import { prepareParams } from "../../utils";
import { format } from "date-fns";
import { store } from "../../redux/store";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Box from "@mui/material/Box";
import theme from "../../theme";
import { setConsumRange } from "../../redux/slices/meterSlice";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useNavigate } from "react-router-dom";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import FormAccordion from "./formComponents/Accordion";
import MeterDatePicker from "./formComponents/MeterDatePicker";
import DatePicker from "./formComponents/DatePicker";
import { useTranslation } from "react-i18next";
import MeterConsumptionsGrafic from "./MeterConsumptionsGrafic";

import { Button } from "@mui/material";
import { values } from "lodash";
const MeterConsumptions = ({
  meterId,
  apartamentMeterId,
  enteredFromCondominium,
  impulsiveMeterId,
  enteredFromParent,
  meter,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [firstRender, setFirstRender] = useState(true);
  const [refreshParams, setRefreshParams] = useState(false);

  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(180);
  const [visibleRows, setVisibleRows] = useState(null);
  const [getConsumptions, { isSuccess }] = store.useLazyGetConsumptionsQuery();
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);
  const [savedPrevDate, setSavedPrevToDate] = useState(null);
  const consumptionsCount = useSelector(
    (state) => state?.meterSlice?.consumptionsCount
  );
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const dates = {
    from: new Date(),
    to: new Date(),
  };
  const heatMeterTypes = ["CALORIE / FRIGORIE", "Calorie", "Frigorie"];
  useEffect(() => {
    console.log("onhuyasviter", meter);
  }, [meter]);
  useEffect(() => {
    const prevDate = new Date(
      Date.UTC(
        dates.to.getFullYear(),
        dates.to.getMonth(),
        dates.to.getDate() - 2
      )
    );
    setSavedPrevToDate(prevDate.toISOString());
  }, []);
  useEffect(() => {
    console.log("svdprvdt", savedPrevDate);
  }, [savedPrevDate]);

  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const fromDate = new Date(now);
  fromDate.setDate(fromDate.getDate() - 7);
  fromDate.setHours(0, 0, 0, 0);
  const newFrom = new Date(
    Date.UTC(
      dates.from.getFullYear(),
      dates.from.getMonth(),
      dates.from.getDate() - 7
    )
  );
  const newTo = new Date(
    Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate())
  );
  const paramsList = {
    page: page,
    per_page: perPage,
    device_id: impulsiveMeterId ?? apartamentMeterId ?? meterId,
    from: searchParams.get("from") ?? newFrom.toISOString(),
    to: searchParams.get("to") ?? newTo.toISOString(),
  };
  const exportParams = {
    from: searchParams.get("from") ?? newFrom.toISOString(),
    device_id: impulsiveMeterId ?? apartamentMeterId ?? meterId,
    to: searchParams.get("to") ?? newTo.toISOString(),
  };
  useEffect(() => {
    if (!firstRender) {
      const toDate = new Date(paramsList.to);
      toDate.setDate(toDate.getDate() + -2);
      setSavedPrevToDate(toDate.toISOString());
    }
  }, [paramsList.to]);

  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            default: paramsList.from ?? fromDate.toISOString(),
          },
          to: {
            type: "string",
            default: paramsList.to ?? now.toISOString(),
          },
        },
      },
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),

      /*   props: {
        className: "bntListSearch",
      }, */
    },
    /*  "ui:ObjectFieldTemplate": objectFieldTemplate, */

    insertion_date: {
      "ui:field": "datepicker",
    },
  };

  const onSubmitClick = (obj) => {
    obj["page"] = page;
    obj["per_page"] = perPage;

    const params = prepareParams(obj);
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meterId}`,
        search: params.toString(),
      });
    }
    dispatch(setConsumRange(null));
    setPage(1);
    setRefreshParams(true);
  };
  const customValidate = (formData, errors) => {
    if (formData.insertion_date.from && formData.insertion_date.to) {
      if (
        formData.insertion_date.from !== "" &&
        formData.insertion_date.to !== "" &&
        formData.insertion_date.from >= formData.insertion_date.to
      ) {
        errors.insertion_date.to.addError(t("date_error"));
      }
    } else {
      /*    if (formData.insertion_date.from && !formData.insertion_date.to) {
        errors.insertion_date.to.addError(t("missing_endDate"));
      } */
      if (!formData.insertion_date.from && formData.insertion_date.to) {
        errors.insertion_date.from.addError(t("missing_startDate"));
      }
    }

    return errors;
  };
  useEffect(() => {
    console.log("penivra", consumptions);
  }, [consumptions]);
  /*   useEffect(() => {
    if (!impulsiveMeterId) {
      getConsumptions({ ...paramsList, device_id: meterId });
      dispatch(setConsumRange(null));
    }
  }, [impulsiveMeterId]); */
  useEffect(() => {
    if (!consumptions) {
      console.log("paramlistto", paramsList.to);
      const toDate = new Date(paramsList.to);
      toDate.setDate(toDate.getDate() + 1);
      getConsumptions({ ...paramsList, to: toDate.toISOString() });
    }
    setFirstRender(false);
  }, [consumptions]);
  /*   useEffect(() => {
    if (!firstRender) {
      getConsumptions({ ...paramsList });
    }
  }, []); */

  useEffect(() => {
    if (refreshParams && !enteredFromCondominium) {
      const toDate = new Date(paramsList.to);
      toDate.setDate(toDate.getDate() + 1);
      console.log("paramlistto", toDate);
      getConsumptions({ ...paramsList, to: toDate.toISOString() });
    }
    if (refreshParams && enteredFromCondominium) {
      getConsumptions({
        ...paramsList,
        from: formData?.insertion_date?.from,
        to: formData?.insertion_date?.to,
      });
    }
    setRefreshParams(false);
  }, [refreshParams]);

  const heatColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
      renderCell: (params) => {
        /*  console.log("sadasdasdasdasda", params.value);
        if (!params.value.warning) {
          return <Typography>{params?.value.date}</Typography>;
        } else {
          return (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{params?.value.date}</Typography>
              <ReportProblemIcon
                sx={{ color: theme.palette.warning.main, ml: 2 }}
              />
            </Box>
          );
        } */
        return <Typography>{params?.value.date}</Typography>;
      },
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "calories",
      headerName: t("calories_consum"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "frigories",
      headerName: t("frigories_consum"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "unit",
      headerName: t("unit"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const heatConsumptions =
    consumptions &&
    consumptions?.reduce((acc, curr) => {
      const existingEntryIndex = acc.findIndex(
        (entry) => entry.date === curr.date
      );
      if (existingEntryIndex !== -1) {
        if (curr.consumption_type === "heat") {
          acc[existingEntryIndex].calories = curr.value;
        } else if (curr.consumption_type === "cold") {
          acc[existingEntryIndex].frigories = curr.value;
        }
      } else {
        const newObj = {
          ...curr,
          calories: curr.consumption_type === "heat" ? curr.value : undefined,
          frigories: curr.consumption_type === "cold" ? curr.value : undefined,
        };
        acc.push(newObj);
      }
      return acc;
    }, []);
  console.log("heatConsumptions", heatConsumptions);
  const allocatorConsum =
    consumptions &&
    consumptions?.reduce((acc, curr) => {
      const existingEntryIndex = acc.findIndex(
        (entry) => entry.date === curr.date
      );
      if (existingEntryIndex !== -1) {
        if (curr.consumption_type === "unconverted_value") {
          acc[existingEntryIndex].unconverted_value = curr.value;
        } else if (curr.consumption_type === "converted_value") {
          acc[existingEntryIndex].converted_value = curr.value;
        }
      } else {
        const newObj = {
          ...curr,
          unconverted_value:
            curr.consumption_type === "unconverted_value"
              ? curr.value
              : undefined,
          converted_value:
            curr.consumption_type === "converted_value"
              ? curr.value
              : undefined,
        };
        acc.push(newObj);
      }
      return acc;
    }, []);

  const heatRows = heatConsumptions?.map((item, i) => ({
    id: i,
    date: {
      date: t("date_val", { val: new Date(item?.date) }),
      warning: savedPrevDate > item.date ? true : false,
    },
    type: item.type,
    calories:
      item?.calories !== null
        ? t("number", {
            val: item.calories / 1000,
          })
        : "--",
    frigories:
      item?.frigories !== null
        ? t("number", {
            val: item.frigories / 1000,
          })
        : "--",
    unit: "kWh",
  }));
  const allocatorColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
      renderCell: (params) => {
        /*   console.log("sadasdasdasdasda", params.value);
        if (!params.value.warning) {
          return <Typography>{params?.value.date}</Typography>;
        } else {
          return (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{params?.value.date}</Typography>
              <ReportProblemIcon
                sx={{ color: theme.palette.warning.main, ml: 2 }}
              />
            </Box>
          );
        } */
        return <Typography>{params?.value.date}</Typography>;
      },
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("simulated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("calculated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "converted_value",
      headerName: t("converted_value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "unconverted_value",
      headerName: t("unconverted_value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const allocatorRows = allocatorConsum?.map((item, i) => ({
    id: i,
    date: {
      date: t("date_val", { val: new Date(item?.date) }),
      warning: savedPrevDate > item.date ? true : false,
    },
    type: item.type,
    converted_value:
      item?.converted_value !== null ? item.converted_value?.toFixed(0) : "--",
    unconverted_value:
      item?.unconverted_value !== null
        ? item.unconverted_value?.toFixed(0)
        : "--",
  }));
  useEffect(() => {
    console.log("allocatorConsum", allocatorConsum);
  }, [allocatorConsum]);
  const onClearClick = () => {
    setFormData((prev) => ({
      ...prev,
      insertion_date: {
        from: null,
        to: null,
      },
    }));

    const params = prepareParams({});
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meterId}`,
        search: params.toString(),
      });
      setRefreshParams(true);
    }
  };
  const rows = consumptions?.map((item, i) => ({
    id: i,
    date: {
      date: t("date_val", { val: new Date(item?.date) }),
      warning: savedPrevDate > item.date ? true : false,
    },
    type: item.type,
    value: t("number", {
      val: item.value,
    }),
    unit: item?.meta?.unit ? item?.meta?.unit : "--",
  }));
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
      renderCell: (params) => {
        console.log("sadasdasdasdasda", params.value);
        /*    if (!params.value.warning) {
          return <Typography>{params?.value.date}</Typography>;
        } */ /* else {
          return (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{params?.value.date}</Typography>
              <ReportProblemIcon
                sx={{ color: theme.palette.warning.main, ml: 2 }}
              />
            </Box>
          );
        } */
        return <Typography>{params?.value.date}</Typography>;
      },
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "value",
      headerName: t("value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "unit",
      headerName: t("unit"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    /* {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 4,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton disabled sx={{ float: "right" }}>
              <DeleteIcon sx={{ ml: 2 }} />
            </IconButton>
            <Link to={`/gateways/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    }, */
  ];
  useEffect(
    () => {
      console.log("ochko", formData?.insertion_date);
    },
    formData?.insertion_date?.from,
    formData?.insertion_date?.to
  );
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1330;
    }
    if (is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1714;
    }
    if (is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1426;
    }
    if (!is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1503;
    }
    if (!is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (!is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1771;
    }
    if (!is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1445;
    }
  };

  const handlePageChange = () => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    if (meter?.attributes?.fields?.type == "13" && heatRows?.length > 0) {
      setVisibleRows(heatRows?.slice(startIndex, endIndex));
    }
    if (meter?.attributes?.fields?.type == "08" && allocatorRows?.length > 0) {
      setVisibleRows(allocatorRows?.slice(startIndex, endIndex));
    }
    if (meter?.attributes?.fields?.type == "07" && rows?.length > 0) {
      setVisibleRows(rows?.slice(startIndex, endIndex));
    }
  };
  useEffect(() => {
    if (!firstRender) {
      handlePageChange();
    }
  }, [page]);
  useEffect(() => {
    console.log("visrows", visibleRows, page);
  }, [visibleRows]);
  useEffect(() => {
    if (visibleRows) {
      dispatch(
        setConsumRange({
          start: visibleRows[0],
          end: visibleRows[visibleRows.length - 1],
        })
      );
    }
  }, [visibleRows]);
  const bearer = localStorage.getItem("user_token");
  const onExportClick = (obj) => {
    const params = prepareParams(obj);
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meterId}`,
        search: params.toString(),
      });
    }

    /*    const params = {
      ...obj,
      condominium_id: condominiumIdentifier,
      from: from,
      to: messagesDateTo.toISOString(),
      grouped: true,
      type: meterType[0],
    }; */
    fetch(
      `${window.BASE_URL}/api/v1/plugin/consumptions/${
        impulsiveMeterId ?? apartamentMeterId ?? meterId
      }/export?${prepareParams(exportParams)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: `Bearer ${bearer}`,
        },
        /*  body: JSON.stringify(prepareBodyWithoutId(params, "plugin")), */
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        console.log("blobik", blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${meter?.attributes?.serial_number}.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        /*   setExportModal(false); */
        /*      dispatch(loadingOff()); */
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
        /*    setExportModal(false); */
        /*  dispatch(setSeverity("error")); */
        /*     dispatch(loadingOff()); */
        /* dispatch(setMsg("Esportazione non è andata a buon fine")); */
      });
  };
  return (
    <Box /*  sx={{ width: enteredFromCondominium ? "80%" : "100%" }} */>
      <FormAccordion width={() => dinamicWidthSelector()}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          customValidate={customValidate}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          /*    /*    onSubmit={(values) => {
            onSubmitClick(values.formData);
          }} */
          validator={validator}
          uiSchema={uiSchema}
        >
          <Box>
            <Button
              onClick={() => onExportClick(formData)}
              sx={{ mr: 2 }}
              variant="contained"
            >
              {t("export")}
            </Button>
            <Tooltip title={t("clear")}>
              <Button
                onClick={() => onClearClick(formData)}
                sx={{ mr: 2 }}
                variant="contained"
              >
                <BackspaceIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("search")}>
              <Button
                disabled={
                  formData?.insertion_date?.from == null ||
                  formData?.insertion_date?.to == null
                }
                variant="contained"
                onClick={() => onSubmitClick(formData)}
              >
                <SearchIcon />
              </Button>
            </Tooltip>
          </Box>
        </Form>
      </FormAccordion>
      {consumptions && (
        <>
          <Box
            sx={{ mt: 4, width: dinamicWidthSelector() }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {!enteredFromCondominium && (
              <Box
                sx={{
                  height: "53vh",
                  width: "50%",
                }}
              >
                {heatMeterTypes.includes(
                  meter?.attributes?.fields?.meter_type
                ) && (
                  <StyledDataGrid
                    disableColumnMenu
                    rows={heatRows}
                    onSortModelChange={(model) => console.log("test")}
                    columns={heatColumns}
                    rowCount={heatConsumptions.length}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      /* !response.isFetching &&  */ setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    // loading={isLoadingTable}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
                {meter?.attributes?.fields?.type == "07" && (
                  <StyledDataGrid
                    disableColumnMenu
                    rows={rows}
                    onSortModelChange={(model) => console.log("test")}
                    columns={columns}
                    rowCount={consumptions?.length}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      /* !response.isFetching &&  */ setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    // loading={isLoadingTable}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
                {meter?.attributes?.fields?.type == "08" && (
                  <StyledDataGrid
                    disableColumnMenu
                    rows={allocatorRows}
                    onSortModelChange={(model) => console.log("test")}
                    columns={allocatorColumns}
                    rowCount={allocatorConsum?.length}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      /* !response.isFetching &&  */ setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    // loading={isLoadingTable}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
              </Box>
            )}
            {enteredFromCondominium && (
              <Box
                sx={{
                  height: "53vh",
                  width: "50%",
                }}
              >
                {heatMeterTypes.includes(
                  meter?.attributes?.fields?.meter_type
                ) && (
                  <StyledDataGrid
                    disableColumnMenu
                    rows={heatRows}
                    onSortModelChange={(model) => console.log("test")}
                    columns={heatColumns}
                    rowCount={heatConsumptions.length}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      /* !response.isFetching &&  */ setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    // loading={isLoadingTable}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
                {meter?.attributes?.fields?.type == "07" && (
                  <StyledDataGrid
                    disableColumnMenu
                    rows={rows}
                    onSortModelChange={(model) => console.log("test")}
                    columns={columns}
                    rowCount={consumptions?.length}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      /* !response.isFetching &&  */ setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    // loading={isLoadingTable}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
                {meter?.attributes?.fields?.type == "08" && (
                  <StyledDataGrid
                    disableColumnMenu
                    rows={allocatorRows}
                    onSortModelChange={(model) => console.log("test")}
                    columns={allocatorColumns}
                    rowCount={allocatorConsum?.length}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    onPageChange={(pageNumber) => {
                      /* !response.isFetching &&  */ setPage(pageNumber + 1);
                    }}
                    page={page - 1}
                    // loading={isLoadingTable}
                    paginationMode="client"
                    getRowHeight={() => "auto"}
                  />
                )}
              </Box>
            )}
            {enteredFromCondominium && (
              <Box
                sx={{
                  height: "54vh",
                  width: "41vw",
                  ml: 5,
                }}
              >
                {consumptions.length > 0 ? (
                  <MeterConsumptionsGrafic
                    meterData={consumptions}
                    type={meter?.attributes?.fields?.type}
                    width={headerAppBarOpen ? 687 : 780}
                    allocatorConsum={allocatorConsum}
                    heatMeter={heatMeterTypes.includes(
                      meter?.attributes?.fields?.meter_type
                    )}
                  />
                ) : (
                  <Typography sx={{ mt: 20 }} align="center" variant="h3">
                    {t("no_available_data")}
                  </Typography>
                )}
              </Box>
            )}
            {!enteredFromCondominium && (
              <Box
                sx={{
                  height: "54vh",
                  width: "50%",
                  ml: 4,
                }}
              >
                {consumptions?.length > 0 ? (
                  <MeterConsumptionsGrafic
                    meterData={consumptions}
                    width={headerAppBarOpen ? 722 : 900}
                    allocatorConsum={allocatorConsum}
                    type={meter?.attributes?.fields?.type}
                    heatMeter={heatMeterTypes.includes(
                      meter?.attributes?.fields?.meter_type
                    )}
                  />
                ) : (
                  <Typography sx={{ mt: 20 }} align="center" variant="h3">
                    {t("no_available_data")}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
export default MeterConsumptions;
