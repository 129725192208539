import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import { store } from "../redux/store";
import validator from "@rjsf/validator-ajv6";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import { useState, useEffect } from "react";
import { loadingOff, loadingOn } from "../redux/slices/loadingSlice";
import {
  Grid,
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { setCondomiumShow } from "../redux/slices/condominiumSlice";
import { useTranslation } from "react-i18next";
import { prepareBodyWithoutId } from "../utils/api_params";
import { v4 as uuidv4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setBlockName } from "../redux/slices/breadCrumbMeterSlice";
import { useNavigate } from "react-router-dom";
import SingleDatePicker from "../components/form/formComponents/SingleDatePicker";
import { condominiumApi } from "../redux/slices/condominiumApi";
import UserAutocomlete from "../components/widgets/UserAutocomlete";
import SecurityWidget from "../components/form/formComponents/customWidgets/SecurityWidget";
import { TreeItem, TreeView } from "@mui/lab";
import LongNameWrapper from "../utils/LongNameWrapper";
import { setCondominiumId } from "../redux/slices/condominiumSlice";
import UserWidget from "../components/users/UserWidget";
import { setMessage, setSeverity } from "../redux/slices/snackbarSlice";
import { offFirstRender } from "../redux/slices/condominiumSlice";
import { useLazyGetCondominiumIdQuery } from "../redux/slices/condominiumApi";
import { useSelector, useDispatch } from "react-redux";
import { setReadingTableTab } from "../redux/slices/condominiumSlice";
import { ChevronRightOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { setTreeExpanded } from "../redux/slices/condominiumSlice";
import { CondominiumTree } from "../components/condominium/CondominiumTree";
import CondominiumReadingsTab from "./CondominiumReadingsTab";
import CondominiumConsumtionsTab from "./CondominiumConsumtionsTab";
import { cleanData } from "../redux/slices/condominiumSlice";
import CondominiumTabs from "../components/condominium/CondominiumTabs";
import { resetConsuptions } from "../redux/slices/condominiumSlice";
import ModalDialog from "../components/meters/ModalDialog";
import { IwdFormAuthWrapper } from "../utils/IwdFormAuthWrapper";
import theme from "../theme";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
export const CondominiumShow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expireDate, setExpireDate] = useState();
  const [inviteModal, setInviteModal] = useState();
  const [scopeSaver, setScopeSaver] = useState(false);
  const [getAssociatedUsers] = store.useLazyGetAssociatedUsersQuery();
  const [disasocModal, setDisasocModal] = useState(false);
  const selectedUser = useSelector((state) => state?.userSlice?.selectedUser);
  const [asscoModal, setAssocModal] = useState(false);
  const [user, setUser] = useState(null);
  const condominumMeter = useSelector(
    (state) => state?.condominiumSlice?.condominumMeter
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const [associateAdmin, { isSuccess: assocSuccess }] =
    store.useAssociateAdminMutation();
  const [disasociateAdmin, { isSuccess: disassocSuccess }] =
    store.useDisassocAdminMutation();
  const [getCondominiumId] = store.useLazyGetCondominiumIdQuery();
  const [updateCondominium, { isSuccess: updateSuccess }] =
    store.useUpdateCondominiumIfnoMutation();
  const [firstRender, setFirstRender] = useState(true);
  const [createdId, setCreatedId] = useState(null);
  const [condominiumCreate, { isSuccess: createSuccess }] =
    store.useCreateCondominiumMutation();
  const associatedUsers = useSelector(
    (state) => state?.condominiumSlice?.associatedUsers
  );
  const [formData, setFormData] = useState({});
  const { id } = useParams();

  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const identifierId = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )?.identifier;
  const condominiumTreeData = useSelector(
    (state) => state?.condominiumSlice?.condominiumTreeData
  );
  const condominiumId = useSelector(
    (state) => state.condominiumSlice.condominiumId
  );
  const security = IwdFormAuthWrapper({ section: "security" });
  useEffect(() => {
    if (createdId) {
      getCondominiumShow(createdId);
      navigate(`/condominiums/${createdId}`);
    }
  }, [createdId]);
  useEffect(() => {
    dispatch(setCondominiumId(id));
  }, [id]);
  /*   useEffect(() => {
    if (identifierId && !condominiumId) {
      getCondominiumId(identifierId);
    }
  }, [identifierId, condominiumId]); */

  useEffect(() => {
    if (id && !associatedUsers) {
      getAssociatedUsers(id);
    }
  }, [id]);
  useEffect(() => {
    console.log("user", user);
  }, [user]);
  useEffect(() => {
    if (id && !condominiumShow) {
      getCondominiumShow(id);
    }
  }, [id, condominiumShow]);
  useEffect(() => {
    if (condominiumShow) {
      setFormData(condominiumShow);
      setFirstRender(false);
    }
  }, [condominiumShow]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [updateSuccess]);

  useEffect(() => {
    console.log("seca", condominiumShow);
  }, [condominiumShow]);
  useEffect(() => {
    console.log("fmdtttt", formData);
  }, [formData]);
  useEffect(() => {
    dispatch(setReadingTableTab("afs"));
  }, []);
  const { t } = useTranslation();

  const openInviteModal = () => {
    setInviteModal(true);
  };
  const closeInviteModal = () => {
    setInviteModal(false);
  };

  const schema = {
    title: "New user",
    type: "object",
    properties: {
      /*  avatar_url: {
              $id: 1,
              title: t("avatar_url"),
              type: "string",
            }, */
      address: {
        $id: "3",
        type: "string",
        title: t("street_address"),
      },
      name: {
        $id: "12",
        type: "string",
        title: t("name"),
        readOnly: id && true,
      },

      province: {
        $id: "4",
        type: "string",
        title: t("province"),
      },
      zip_code: {
        $id: "2",
        type: "string",
        title: t("zip_code"),
      },
    },
    required: ["address", "name", "province", "zip_code"],
  };
  const securitySchema = {
    title: "New user",
    type: "object",
    properties: {
      /*  avatar_url: {
              $id: 1,
              title: t("avatar_url"),
              type: "string",
            }, */
      address: {
        $id: "3",
        type: "string",
        title: t("street_address"),
      },
      name: {
        $id: "12",
        type: "string",
        title: t("name"),
        readOnly: id && true,
      },

      province: {
        $id: "4",
        type: "string",
        title: t("province"),
      },
      zip_code: {
        $id: "2",
        type: "string",
        title: t("zip_code"),
      },

      security: {
        $id: "2",
        type: "array",
        title: t("security"),
        default: condominiumShow?.security.acl,
      },
    },
    required: ["address", "name", "province", "zip_code"],
  };
  useEffect(() => {
    dispatch(offFirstRender());
  }, []);
  const onCreateClick = () => {
    console.log(formData);

    if (id) {
      /* const data = {
        name: formData.name,
        address: formData.address,
        zip_code: formData.zip_code,
        province: formData.province,
        coordinates: formData.coordinates,
      };
      updateCondominium({
        attributes: data,
        id: id,
      }); */
      const attributes = {
        fields: {
          coordinates: condominumMeter?.attributes?.coordinates,
          class: condominumMeter?.attributes.class,
          tag: condominumMeter?.attributes?.tag,
          serial_number: condominumMeter?.attributes?.identifier,
          condominium_id: condominumMeter?.id,
          name: condominumMeter?.attributes?.name,
          identifier: condominumMeter?.attributes?.identifier,
          identifier_type: condominumMeter?.attributes?.identifier_type,
          address: formData.address,
          zip_code: formData.zip_code,
          province: formData.province,
        },
        meta_data: {},
      };
      const securityAttributes = security && {
        fields: {
          coordinates: condominumMeter?.attributes?.coordinates,
          class: condominumMeter?.attributes.class,
          tag: condominumMeter?.attributes?.tag,
          serial_number: condominumMeter?.attributes?.identifier,
          condominium_id: condominumMeter?.id,
          name: condominumMeter?.attributes?.name,
          identifier: condominumMeter?.attributes?.identifier,
          identifier_type: condominumMeter?.attributes?.identifier_type,
          address: formData.address,
          zip_code: formData.zip_code,
          province: formData.province,
          security: {
            acl: [...formData?.security],
            groups: ["/network_adapter_manager_router", "/codec_manager"],
          },
        },
        meta_data: {},
      };

      updateCondominium({
        twinId: condominumMeter?.attributes?.digital_twin_id,
        instanceId: condominiumId,
        attributes: security ? securityAttributes : attributes,
      });
    } else {
      condominiumCreate({
        ...formData,
        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  {
                    name: "/admin",
                    scopes: ["Elixir.show", "Elixir.update"],
                  },
                ],
                groups: ["/admin"],
              },
        coordinates: {
          coordinates: [45.416667, 11.883333],
          type: "Point",
        },
      })
        .unwrap()
        .then((data) => setCreatedId(data.data.id))
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
    window.sessionStorage.setItem("bcName", formData.name);
  };
  useEffect(() => {
    if (condominiumShow) {
      window.sessionStorage.setItem("bcName", condominiumShow?.name);
    }
  }, [condominiumShow?.name]);

  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("save"),
      classNames: "submit-button",
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    security: {
      "ui:field": "security",
    },
  };

  const customValidate = (formData, errors) => {
    if (formData?.roles?.length < 1) {
      errors?.roles?.addError("is a required property");
    }
    return errors;
  };

  const fields = {
    security: SecurityWidget,
  };
  const onAssociateAdminClick = () => {
    if (condominiumShow?.building_administrator_id) {
      setAssocModal(true);
    } else {
      const attributes = {
        condominium_id: +condominiumId,
        condominium_administrator_id: user,
      };
      associateAdmin(attributes);
    }
  };
  const onDisasocClick = () => {
    setDisasocModal(true);
  };
  useEffect(() => {
    dispatch(resetConsuptions());
  }, []);
  const assocAdmin = () => {
    const attributes = {
      condominium_id: +condominiumId,
      condominium_administrator_id: user,
    };
    associateAdmin(attributes);
    setAssocModal(false);
  };
  const disasochAmdin = () => {
    disasociateAdmin(condominiumId);
    setDisasocModal(false);
  };
  useEffect(() => {
    if (assocSuccess || disassocSuccess) {
      getCondominiumShow(id);
    }
  }, [assocSuccess, disassocSuccess]);
  useEffect(() => {
    if (assocSuccess) {
      dispatch(setMessage(t("assoc_succ")));
    }
  }, [assocSuccess]);
  useEffect(() => {
    if (disassocSuccess) {
      dispatch(setMessage(t("dissoc_succ")));
    }
  }, [disassocSuccess]);

  useEffect(() => {
    console.log("xprdt", expireDate);
  }, [expireDate]);
  const bearer = localStorage.getItem("user_token");
  const onCreateInviteClick = () => {
    dispatch(loadingOn());
    const uuid = uuidv4();
    const attributes = {
      root_device_id: id.toString(),
      expires_at: expireDate.to_date,
      uuid: uuid,
    };

    fetch(`${window.BASE_URL}/api/v1/association_code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify(
        prepareBodyWithoutId(attributes, "association_code")
      ),
    })
      .then((response) => {
        const contentDisposition = response.headers.get("content-disposition");
        console.log("respya", contentDisposition);
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }

        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        console.log("юра", url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${condominiumShow?.name}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        dispatch(loadingOff());
        setInviteModal(false);
      })
      .catch((error) => {
        console.error("Error during fetch:", error);

        dispatch(setSeverity("error"));
        dispatch(loadingOff());
        dispatch(
          setMessage(
            `L'esportazione non è riuscita. Contatta l'amministratore di sistema per ulteriore assistenza.`
          )
        );
      });
  };
  useEffect(() => {
    dispatch(setBlockName(condominiumShow?.name));
    return () => dispatch(setBlockName(null));
  }, [condominiumShow?.name]);
  return (
    <>
      <ModalDialog
        open={disasocModal}
        title={t("disasoc_adm")}
        cancell={false}
        close={() => setDisasocModal(false)}
      >
        <Box width={300} display={"flex"} justifyContent={"space-between"}>
          <Button
            onClick={() => setDisasocModal(false)}
            sx={{ mt: 2 }}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button onClick={disasochAmdin} sx={{ mt: 2 }} variant="contained">
            {t("confirm")}
          </Button>
        </Box>
      </ModalDialog>
      <ModalDialog
        open={asscoModal}
        title={t("reassoc_adm")}
        cancell={false}
        close={() => setAssocModal(false)}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={() => setAssocModal(false)}
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button onClick={assocAdmin} sx={{ mt: 2 }} variant="contained">
            {t("confirm")}
          </Button>
        </Box>
      </ModalDialog>
      {!id && (
        <Typography
          sx={{ mb: 4, color: theme.palette.primary.main }}
          variant="h2"
        >
          {t("new_condominium")}
        </Typography>
      )}
      {condominiumShow && (
        <LongNameWrapper
          text={condominiumShow?.name}
          size={appBarIsOpen ? "75vw" : "95vw"}
          variant={"h2"}
          maxLength={100}
        />
      )}
      <Grid container>
        {id && (
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {condominiumShow && (
                <CondominiumTree
                  condominium={condominiumTreeData}
                ></CondominiumTree>
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={condominiumShow ? 10 : 12}>
          {id && !isFetching && !firstRender && (
            <CondominiumTabs
              mainTab={
                <Box>
                  <Form
                    schema={security ? securitySchema : schema}
                    uiSchema={uiSchema}
                    fields={fields}
                    formData={formData}
                    onSubmit={onCreateClick}
                    onChange={(changeEvent) =>
                      setFormData(changeEvent.formData)
                    }
                    validator={validator}
                    showErrorList={false}
                    customValidate={customValidate}
                    noHtml5Validate
                    children={true}
                  >
                    <Box
                      sx={{ mt: 2 }}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <IwdAuthWrapper
                        children={
                          <Button onClick={onCreateClick} variant="contained">
                            {t("save")}
                          </Button>
                        }
                        section={"block_save_btns"}
                      />
                    </Box>
                  </Form>

                  <IwdAuthWrapper
                    children={
                      <Accordion sx={{ mt: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {`${t("building_administrator")} ${
                            selectedUser && ": " + selectedUser
                          }`}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ mt: 3 }}>
                            {/*        <UserWidget
                              onChange={setUser}
                              label={t("building_administrator")}
                              default={
                                condominiumShow?.building_administrator_id &&
                                condominiumShow?.building_administrator_id
                              }
                            /> */}
                            <UserAutocomlete deviceId={id} />
                            <Box
                              sx={{ mt: 2 }}
                              display={"flex"}
                              justifyContent={"flex-end"}
                            >
                              {/*  <Button
                                sx={{
                                  mr: 2,
                                  background: theme.palette.error.main,
                                  "&:hover": {
                                    backgroundColor: theme.palette.error.main,
                                  },
                                }}
                                variant="contained"
                                disabled={
                                  !condominiumShow?.building_administrator_id
                                }
                                onClick={onDisasocClick}
                              >
                                {t("disasoc_adm")}
                              </Button>
                              <Button
                                onClick={onAssociateAdminClick}
                                disabled={!user}
                                variant="contained"
                              >
                                {t("assoc_adm")}
                              </Button> */}
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    }
                    section={"block_admin"}
                  />
                </Box>
              }
              readings={<CondominiumReadingsTab condominiumId={id} />}
              consume={<CondominiumConsumtionsTab condominiumId={id} />}
            />
          )}
          {!id && !isFetching && (
            <Form
              schema={security ? securitySchema : schema}
              uiSchema={uiSchema}
              fields={fields}
              formData={formData}
              onSubmit={onCreateClick}
              onChange={(changeEvent) => setFormData(changeEvent.formData)}
              validator={validator}
              showErrorList={false}
              customValidate={customValidate}
              noHtml5Validate
              children={true}
            >
              <IwdAuthWrapper
                children={
                  <Box
                    sx={{ mt: 2 }}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Button onClick={onCreateClick} variant="contained">
                      {t("save")}
                    </Button>
                  </Box>
                }
                section={"block_save_btns"}
              />
            </Form>
          )}
        </Grid>
      </Grid>
    </>
  );
};
