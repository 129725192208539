import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Table from "../form/formComponents/Table";
import { store } from "../../redux/store";
import CreateMeterFromBlock from "../form/CreateMeterFromBlock";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ApartamentMeterTable from "./ApartamentMeterTable";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        /* if (i == 5 || i == 4) {
                      return (
                        <Grid key={element.id} alignItems="center" item xs={12}>
                          {element.content}
                        </Grid>
                      );
                    } */
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const typeEnum = [
  { const: "AFS" },
  { const: "ACS" },
  { const: "CALORIE / FRIGORIE" },
  /*   { const: "CC -H" }, */
  { const: "RIPARTITORE" },
];
const ApartamentMeter = ({ open }) => {
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  const [firstRender, setFirstRender] = useState(true);
  const [getCondominiumShow] = store.useLazyCondominiumShowQuery();
  const { id, subCondominumName, apartmentName, serial } = useParams();
  const meterUpdatedFromCondominium = localStorage.getItem(
    "meterUpdatedFromCondominium"
  );
  const [type, setType] = useState(null);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState(12);
  const devices = useSelector((state) => state?.clusterSlice?.devices);
  const params = {
    page: page,
    per_page: perPage,
    meter_type: type ?? "AFS",
  };
  useEffect(() => {
    if (!firstRender && open) {
      console.log("case1");
      getByName(["altior/device", { ...params }]);
    }
  }, [page, open]);
  useEffect(() => {
    console.log("respik", resp);
  }, [resp]);
  useEffect(() => {
    if (firstRender && !resp.data && open) {
      console.log("case2");
      getByName(["altior/device", { ...params }]);
      setFirstRender(false);
    }
  }, [firstRender, resp, open]);

  const schema = {
    title: "New apartment",
    type: "object",
    properties: {
      meter_type: {
        $id: "1",
        type: "string",
        title: t("type"),
        oneOf: [...typeEnum],
        default: "AFS",
      },
      serial_number: {
        $id: "2",
        title: t("serial"),
        type: "string",
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };
  const onSubmitClick = (obj) => {
    setType(obj?.meter_type);
    setPage(1);
    const params = {
      ...obj,
      page: 1,
      per_page: perPage,
      /*   tag: "AltiorEdge", */
      /*   class: "AltiorEdge", */
    };
    getByName(["altior/device", { ...params }]);
  };
  return (
    <>
      <Box width={1200}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {t("substitutution_from_exists")}
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 2 }}>
              <Form
                schema={schema}
                /*      fields={fields} */
                formData={formData}
                /*      customValidate={customValidate} */
                showErrorList={false}
                onChange={(changeEvent) => setFormData(changeEvent.formData)}
                onSubmit={(values) => {
                  onSubmitClick(values.formData);
                }}
                validator={validator}
                uiSchema={uiSchema}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <ApartamentMeterTable
                data={resp?.data?.data}
                response={resp}
                perPage={perPage}
                page={page}
                setPage={setPage}
                /* hideAccordion={hideAccordion}  */
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {t("create_meter")}
          </AccordionSummary>
          <AccordionDetails>
            <CreateMeterFromBlock />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
export default ApartamentMeter;
