import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

import { store } from "../../../../redux/store";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../../../../theme";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
const SecurityWidget = (props) => {
  const [name, setName] = useState("");
  const condominiumShow = useSelector(
    (state) => state?.condomoniumSlice?.condominiumShow
  );
  const [getRoles] = store.useLazyGetAllrolesQuery();
  const [scopes, setScopes] = useState([
    "create",
    "update",
    "delete",
    "index",
    "show",
  ]);
  const [addParam, setAddParam] = useState(false);
  const roles = useSelector((state) => state?.aegisSlice?.roles);
  const userRoles = useSelector((state) => state?.userPreferenceSlice?.roles);
  const [parameters, setParameters] = useState(props?.schema?.default ?? []);
  const [autocompleteParams, setAutocompleteParams] = useState([]);
  const currentPath = window.location.pathname;
  const scopesNames = [
    { label: "create" },
    { label: "update" },
    { label: "delete" },
    { label: "index" },
    { label: "show" },
  ];
  const mappedRoles =
    userRoles?.includes("admin") || userRoles?.includes("/admin")
      ? roles?.map((item) => ({
          label: item,
        }))
      : userRoles?.map((item) => ({
          label: item,
        }));
  useEffect(() => {
    props?.onChange(parameters);
  }, [parameters]);
  const onSaveClick = () => {
    setParameters((prev) => [
      ...prev,
      {
        name: name,
        scopes: [...scopes],
      },
    ]);
    setName("");
    /*   setScopes([]); */
    setAddParam(false);
  };
  useEffect(() => {
    if (!roles) {
      getRoles();
    }
  }, []);
  useEffect(() => {
    console.log("propsssdada", props);
  }, [props]);
  useEffect(() => {
    if (name) {
      onSaveClick();
    }
  }, [name]);
  const onDeleteClick = (i) => {
    setParameters(parameters?.filter((el, ind) => ind !== i));
  };
  useEffect(() => {
    console.log("scopes", parameters, autocompleteParams, currentPath);
  }, [parameters, autocompleteParams]);
  const updateNameAtIndex = (index, name) => {
    const newParam = [...parameters];
    newParam[index] = { ...newParam[index], name: name };
    setParameters(newParam);
  };
  const updateScopesAtIndex = (index, newScopes) => {
    const newParam = [...parameters];
    newParam[index] = { ...newParam[index], scopes: newScopes };
    setParameters(newParam);
  };
  useEffect(() => {
    if (window.SECURITY_AUTOCOMPLETE && currentPath === "/condominiums/new") {
      setParameters(
        userRoles?.map((item) => ({
          name: item,
          scopes: [...scopes],
        }))
      );
    }
  }, [window.SECURITY_AUTOCOMPLETE]);
  const mappedScopes = parameters?.map((item, i) => (
    <>
      <Grid item xs={6}>
        {/*    <TextField
          label="name"
          value={item?.name}
          onChange={(e) => updateNameAtIndex(i, e.target.value)}
          fullWidth
        /> */}
        <Autocomplete
          sx={{ mb: 3 }}
          disablePortal
          id="combo-box-demo"
          options={mappedRoles ?? []}
          value={item?.name}
          onChange={(event, newValue) => {
            updateNameAtIndex(i, newValue?.label);
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="name " />}
        />
      </Grid>
      <Grid item xs={5.5}>
        <Autocomplete
          sx={{ mb: 3 }}
          disablePortal
          multiple
          id="combo-box-demo"
          defaultValue={[...item?.scopes]}
          options={scopesNames?.filter(
            (scope) => !item.scopes.includes(scope.label)
          )}
          onChange={(event, newValue) => {
            updateScopesAtIndex(
              i,
              newValue?.map((item) => (item.label ? item.label : item))
            );
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="scopes " />}
        />
      </Grid>
      <Grid item xs={0.5}>
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton onClick={() => onDeleteClick(i)} sx={{ mt: 1 }}>
            <DeleteIcon sx={{ color: theme.palette.error.main }} />
          </IconButton>
        </Box>
      </Grid>
    </>
  ));
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Security
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {mappedScopes}
          {parameters.length > 0 && !addParam && (
            <>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  width={"99.5%"}
                  justifyContent={"flex-end"}
                >
                  <IconButton onClick={() => setAddParam(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              </Grid>
            </>
          )}
          {(parameters.length == 0 || addParam) && (
            <>
              <Grid item xs={6}>
                {/*     <TextField
                  fullWidth
                  label="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                /> */}
                <Autocomplete
                  sx={{ mb: 3 }}
                  disablePortal
                  id="combo-box-demo"
                  options={mappedRoles ?? []}
                  onChange={(event, newValue) => {
                    setName(newValue?.label);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="name " />
                  )}
                />
              </Grid>

              <Grid item xs={5.5}>
                <Autocomplete
                  sx={{ mb: 3 }}
                  disablePortal
                  multiple
                  id="combo-box-demo"
                  options={scopesNames?.filter(
                    (item) => !scopes.includes(item.label)
                  )}
                  onChange={(event, newValue) => {
                    setScopes(newValue?.map((item) => item.label));
                  }}
                  fullWidth
                  defaultValue={[
                    scopesNames[0],
                    scopesNames[1],
                    scopesNames[2],
                    scopesNames[3],
                    scopesNames[4],
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="scopes " />
                  )}
                />
              </Grid>
              {/*  <Grid item xs={0.5}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    mt: 1,
                    ml: parameters.length > 0 && addParam ? 2.2 : 0,
                  }}
                >
                  <IconButton onClick={onSaveClick} disabled={!name}>
                    <DoneIcon
                      sx={{ color: name && theme.palette.success.main }}
                    />
                  </IconButton>
                  {parameters.length > 0 && addParam && (
                    <IconButton onClick={() => setAddParam(false)}>
                      <CloseIcon sx={{ color: theme.palette.error.main }} />
                    </IconButton>
                  )}
                </Box>
              </Grid> */}
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SecurityWidget;
