import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import PeopleIcon from "@mui/icons-material/People";
import theme from "../theme";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import StorageIcon from "@mui/icons-material/Storage";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InsightsIcon from "@mui/icons-material/Insights";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BalanceIcon from "@mui/icons-material/Balance";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import IconButton from "@mui/material/IconButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import AllLastReadings from "./AllLastReadings";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { IwdTreeItem } from "../utils/IwdTreeItem";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CommitIcon from "@mui/icons-material/Commit";
import ExploreIcon from "@mui/icons-material/Explore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import GatewayIndex from "./gateway/GatewayIndex";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ErrorIcon from "@mui/icons-material/Error";
import { HolidayVillage } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LongNameWrapper from "../utils/LongNameWrapper";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ReportIcon from "@mui/icons-material/Report";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

const drawerWidth = 280;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: 3,
  padding: theme.spacing(0, 1),
  /*   ...theme.mixins.toolbar, */
  height: "30px",
}));
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "normal",
  overflowWrap: "break-word",
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  background: "#2a2a2a",
  boxSizing: "border-box",
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  }),
  ...(!open && {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  }),
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    ...(open && {
      overflowX: "hidden",
    }),
    ...(!open && {
      width: 0,
      overflowX: "hidden",
    }),
  },
}));

export function SideMenu({
  expanded,
  selected,
  handleToggle,
  handleSelect,
  open,
  handleDrawerClose,
  items,
}) {
  const { t } = useTranslation();
  const bearer = localStorage.getItem("user_token");
  const [imageLoaded, setImageLoaded] = useState(false);
  const logoId = useSelector((state) => state?.configurationSlice?.logoId);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      classes={{
        paper: {
          background: "#90a4ae",
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Box maxHeight={110} sx={{ mb: 5 }}>
        <React.Fragment>
          {!imageLoaded && <Box sx={{ height: window?.LOGO?.height }}></Box>}
          <img
            style={{
              height: window?.LOGO?.height,
              width: window?.LOGO?.width,
              marginRight: window?.LOGO?.mr,
              marginLeft: window?.LOGO?.ml,
              marginTop: window?.LOGO?.mt,
              marginBottom: window?.LOGO?.mb,
              fill: window?.LOGO?.color,
              display: imageLoaded ? "block" : "none",
            }}
            src={`${window.BASE_URL}/api/v1/image/${logoId}?token=${bearer}`}
            onLoad={handleImageLoad}
            alt="Logo"
          />
        </React.Fragment>
      </Box>

      <SimpleTreeView
        aria-label="file system navigator"
        /*  expanded={expanded}
        selected={selected} */
        /*         onNodeToggle={handleToggle}
        onNodeSelect={handleSelect} */
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        sx={{
          height: 240,
          flexGrow: 1,
          maxWidth: drawerWidth,
          overflowY: "auto",
        }}
      >
        <List>
          <IwdTreeItem
            uuid={items.dashboard}
            icon={<DashboardIcon />}
            title={t("dashboard")}
            section={"dashboard"}
          >
            <IwdTreeItem
              uuid={items.metrics}
              icon={<ScatterPlotIcon />}
              title={t("metrics")}
              section={"dashboard"}
              link={"/metrics"}
            />
            <IwdTreeItem
              uuid={items.statistics}
              icon={<InsightsIcon />}
              title={t("statistics")}
              section={"statistics"}
            >
              <IwdTreeItem
                uuid={items.statisticList}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"statistics_list"}
                link={"/statistics"}
              />
              <IwdTreeItem
                uuid={items.createStatistic}
                icon={<AddCircleIcon />}
                title={t("add_statistic")}
                section={"statistics_create"}
                link={"/statistics/create"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.widgets}
              icon={<WidgetsIcon />}
              title={t("widgets")}
              section={"widgets"}
            >
              <IwdTreeItem
                uuid={items.widgetList}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"widgets_list"}
                link={"/widgets"}
              />
              <IwdTreeItem
                uuid={items.widgetCreate}
                icon={<AddCircleIcon />}
                title={t("add_widget")}
                section={"widgets_create"}
                link={"/widgets/create"}
              />
            </IwdTreeItem>
          </IwdTreeItem>

          <IwdTreeItem
            uuid={items.condominiums}
            icon={<HolidayVillage />}
            title={t("buildings")}
            section={"condominums"}
            /*    link={"/condominiums"} */
          >
            <IwdTreeItem
              uuid={items.condominiumList}
              icon={<FormatListBulletedIcon />}
              title={t("manage")}
              section={"condominums"}
              link={"/condominiums"}
            />
            <IwdTreeItem
              uuid={items.balances}
              icon={<BalanceIcon />}
              title={t("balances")}
              section={"balance"}
              link={"/balances"}
            />
          </IwdTreeItem>
          <IwdTreeItem
            uuid={items.meters}
            icon={<GasMeterIcon />}
            title={t("meters")}
            section={"devices_meters"}
          >
            <IwdTreeItem
              uuid={items.metersList}
              icon={<FormatListBulletedIcon />}
              title={t("manage")}
              section={"devices_meters"}
              link={"/meters"}
            />
            <IwdTreeItem
              uuid={items.allreadings}
              icon={<AutoStoriesIcon />}
              title={t("readings")}
              section={"all_readings"}
              link={"/readings"}
            />
            <IwdTreeItem
              uuid={items.trilliant}
              icon={<AssessmentIcon />}
              title={t("Trilliant")}
              section={"trilliant"}
              link={"/trilliant"}
            />
            <IwdTreeItem
              uuid={items.groups}
              icon={<Diversity2Icon />}
              title={t("groups")}
              section={"groups_list"}
              link={"/groups"}
            />
            <IwdTreeItem
              uuid={items.errors}
              icon={<ErrorIcon />}
              title={t("errors")}
              section={"errors"}
            >
              <IwdTreeItem
                uuid={items.errorTypes}
                icon={<NewReleasesIcon />}
                title={t("errors_types")}
                section={"errors"}
              >
                <IwdTreeItem
                  uuid={items.errorTypesList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"errors"}
                  link={"/errortypes"}
                />
                <IwdTreeItem
                  uuid={items.errorTypesCreate}
                  icon={<AddCircleIcon />}
                  title={t("create_errors_types")}
                  section={"errors"}
                  link={"/errortypes/create"}
                />
              </IwdTreeItem>

              <IwdTreeItem
                uuid={items.errorAssociation}
                icon={<ReportIcon />}
                title={t("error_association")}
                section={"errors"}
              >
                <IwdTreeItem
                  uuid={items.errorAssociationList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"errors"}
                  link={"/errorassociation"}
                />
                <IwdTreeItem
                  uuid={items.errorAssociationCreate}
                  icon={<AddCircleIcon />}
                  title={t("create_errors_association")}
                  section={"errors"}
                  link={"/errorassociation/create"}
                />
              </IwdTreeItem>
            </IwdTreeItem>
          </IwdTreeItem>

          <IwdTreeItem
            uuid={items.operations}
            icon={<TextSnippetIcon />}
            title={t("operations")}
            section={"operations"}
          >
            <IwdTreeItem
              uuid={items.userList}
              icon={<PeopleIcon />}
              title={t("users")}
              section={"users"}
              link={"/users"}
            />
            <IwdTreeItem
              uuid={items.userGroups}
              icon={<GroupsIcon />}
              title={t("user_groups")}
              section={"usergroups"}
              link={"/usergroups"}
            />
            <IwdTreeItem
              uuid={items.enduser_config}
              icon={<ManageAccountsIcon />}
              title={t("enduser_config")}
              section={"enduser_config"}
              link={"/enduser/configs"}
            />
            <IwdTreeItem
              uuid={items.dexmenu}
              icon={<ExploreIcon />}
              title={t("dex")}
              section={"dex"}
            >
              <IwdTreeItem
                uuid={items.dexind}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"dex"}
                link={"/dex"}
              />
              <IwdTreeItem
                uuid={items?.dexadd}
                icon={<AddCircleIcon />}
                title={t("dex_create")}
                section={"dex"}
                link={"/dex/create"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.digitalTwins}
              icon={<MemoryOutlinedIcon />}
              title={t("digital_twins")}
              section={"digital_twins"}
              link={"/digitaltwins"}
            />
            <IwdTreeItem
              uuid={items.alarms}
              icon={<NotificationsActiveIcon />}
              title={t("alarms")}
              section={"alarms"}
              link={"/alarms"}
            />
            <IwdTreeItem
              uuid={items.anomalies}
              icon={<WarningAmberIcon />}
              title={t("anomalies")}
              section={"anomalies"}
              link={"/anomalies"}
            />
            <IwdTreeItem
              uuid={items.audit}
              icon={<VerifiedUserIcon />}
              title={t("audit")}
              section={"audit"}
              link={"/audit"}
            />
            <IwdTreeItem
              uuid={items.jobs}
              icon={<WorkHistoryIcon />}
              title={t("jobs")}
              section={"jobs"}
              link={"/jobs"}
            />
          </IwdTreeItem>

          <IwdTreeItem
            uuid={items.worklists}
            icon={<ListAltIcon />}
            title={t("AMR")}
            section={"worklist"}
          >
            <IwdTreeItem
              uuid={items.wokrlistEditor}
              icon={<EditNoteIcon />}
              title={t("worklisteditor")}
              section={"worklist"}
            >
              <IwdTreeItem
                uuid={items.workListEditorList}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"worklist"}
                link={"worklisteditors"}
              />
              <IwdTreeItem
                uuid={items.worklistEditorCreate}
                icon={<AddCircleIcon />}
                title={t("create_worklist_editor")}
                section={"worklist"}
                link={"worklisteditor/create"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.workList}
              icon={<FactCheckOutlinedIcon />}
              title={t("worklists")}
              section={"worklist"}
            >
              <IwdTreeItem
                uuid={items.workListIndex}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"worklist"}
                link={"worklists"}
              />
              <IwdTreeItem
                uuid={items.workListCreate}
                icon={<AddCircleIcon />}
                title={t("create_worklist")}
                section={"worklist"}
                link={"worklists/create"}
              />
            </IwdTreeItem>
          </IwdTreeItem>
          <IwdTreeItem
            uuid={items.network}
            icon={<RssFeedIcon />}
            title={t("network")}
            section={"network"}
          >
            <IwdTreeItem
              uuid={items.gateways}
              icon={<SettingsIcon />}
              title={t("gateways")}
              section={"list_LPWAN"}
              link={"/gateways"}
            />
            <IwdTreeItem
              uuid={items.lora}
              icon={<StorageIcon />}
              title={t("lora")}
              section={"lora"}
              link={"/lora"}
            />
            <IwdTreeItem
              uuid={items.edge}
              icon={<CommitIcon />}
              title={t("edge_controller")}
              section={"edge"}
              link={"/edge"}
            />
          </IwdTreeItem>
        </List>
      </SimpleTreeView>

      <Box sx={{ padding: 1, color: theme.palette.version }}>
        Version: {process.env.REACT_APP_VERSION}
      </Box>
    </Drawer>
  );
}
