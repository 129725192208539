import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchOnServer from "./formComponents/SearchOnServer";
import DatePicker from "./formComponents/DatePicker";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { useDispatch, useSelector } from "react-redux";
import ModalDialog from "../meters/ModalDialog";
import DtwinCustomField from "./formComponents/DtwinCustomField";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { unSetClusterId } from "../../redux/slices/clusterSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { prepareParams, objectFieldTemplate } from "../../utils";
import SaveIcon from "@mui/icons-material/Save";
import MetersTableTab from "./formComponents/MetersTableTab";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import ExportButton from "./formComponents/ExportButton";
import Table from "./formComponents/Table";
import { setUpdateAfterDelete } from "../../redux/slices/meterSlice";
import theme from "../../theme";
import { setMeterTrashId } from "../../redux/slices/meterSlice";
import { store } from "../../redux/store";
import ErrorsHandler from "../../utils/ErrorsHandler";
import SingleDatePicker from "./formComponents/SingleDatePicker";
import FormAccordion from "./formComponents/Accordion";
import MapDevice from "./formComponents/MapDevice";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSearchParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";

import { Box } from "@mui/system";
import { Grid } from "@mui/material";

const fields = {
  serverSearch: SearchOnServer,
  datepicker: SingleDatePicker,
  dtwin: DtwinCustomField,
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Meters = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const devices = useSelector((state) => state?.clusterSlice?.devices);
  const updateAfterDelete = useSelector(
    (state) => state?.meterSlice?.updateAfterDelete
  );
  const preferences = useSelector(
    (state) => state?.userPreferenceSlice.preference
  )?.preferences;
  const selectedClusterId = useSelector(
    (state) => state?.clusterSlice?.selectedClusterId
  );
  const userPreferenceFirstRender = useSelector(
    (state) => state?.userPreferenceSlice?.firstRender
  );
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  const [getPreference] = store.useLazyGetPreferenceQuery();
  const [refreshParams, setRefreshParams] = useState(false);
  const [meterType, setMeterType] = useState(["AFS"]);
  const [iconHover, setIconHover] = useState(true);
  const [hideAccordion, setHideAccordion] = useState(false);
  const [meterDialogOpen, setMeterDialogOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [preferenceLoading, setPreferenceLoading] = useState();
  const [perPage, setPerPage] = useState(12);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  const mapCoordinates = resp?.data?.data
    ?.map((item) => item?.attributes?.fields?.coordinates)
    ?.map((item) => item?.coordinates);
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),

      props: {
        className: "bntListSearch",
      },
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    /*    digital_twin_id: {
      "ui:field": "serverSearch",
    }, */
    digital_twin_id: {
      "ui:field": "dtwin",
    },
    tags: {
      "ui:field": "serverSearch",
    },
    inserted_at: {
      "ui:field": "datepicker",
    },
  };
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });
  useEffect(() => {
    console.log("map coordinates", mapCoordinates);
  }, [mapCoordinates]);
  const onIconHover = () => {
    setIconHover(!iconHover);
  };
  useEffect(() => {
    if (userPreferenceFirstRender) {
      getPreference();
    }
  }, []);
  useEffect(() => {
    console.log("mtrtp", meterType);
  }, [meterType]);
  const onCancellClick = () => {
    dispatch(unSetClusterId());
    setIconHover(true);
  };

  const paramsList = {
    digital_twin_id: searchParams.get("digital_twin_id") && [searchParams.get("digital_twin_id").split(",")], // prettier-ignore
    tags: searchParams.get("tags") && [searchParams.get("tags").split(",")],
    /*  from: searchParams.get("from"),
    to: searchParams.get("to"), */
    inserted_at: searchParams.get("inserted_at"),
    serial_number: searchParams.get("serial_number"),
    mechanical_serial_number: searchParams.get("mechanical_serial_number"),
    address: searchParams.get("address"),
    diameter: searchParams.get("diameter"),
    pod: searchParams.get("pod"),
    page: page,
    per_page: perPage,
    in_error: searchParams.get("in_error"),
    meter_not_seen: searchParams.get("meter_not_seen"),
    meter_types: JSON.stringify(meterType),

    //TODO: remove it, added only for demo propouse
    /*  class: "AltiorEdge", */
  };

  useEffect(() => {
    localStorage.removeItem("alarm_date");
  }, []);

  useEffect(() => {
    if (refreshParams) {
      getByName(["altior/device", { ...paramsList }]);
      setRefreshParams(false);
    }
  }, [refreshParams, page]);
  useEffect(() => {
    if (updateAfterDelete) {
      getByName(["altior/device", { ...paramsList }]);
      dispatch(setUpdateAfterDelete(false));
    }
  }, [updateAfterDelete]);
  useEffect(() => {
    console.log("hideacc", hideAccordion);
  }, [hideAccordion]);

  const customValidate = (formData, errors) => {
    if (formData?.insertion_date?.from && formData.insertion_date?.to) {
      if (
        formData?.insertion_date?.from !== "" &&
        formData?.insertion_date?.to !== "" &&
        formData?.insertion_date?.from >= formData?.insertion_date?.to
      ) {
        errors?.insertion_date?.to.addError(t("date_error"));
      }
    } else {
      if (formData?.insertion_date?.from && !formData?.insertion_date?.to) {
        errors?.insertion_date?.to.addError(t("missing_endDate"));
      }
      if (!formData?.insertion_date?.from && formData?.insertion_date?.to) {
        errors?.insertion_date?.from?.addError(t("missing_startDate"));
      }
    }

    return errors;
  };
  const onAccordionClick = () => {
    setHideAccordion(!hideAccordion);
  };
  const onSubmitClick = (obj) => {
    obj["page"] = page;
    obj["per_page"] = perPage;
    console.log("huebj", obj);

    const params = prepareParams(obj);
    navigate({
      pathname: "/meters",
      search: params.toString(),
    });

    setPage(1);
    setRefreshParams(true);
  };

  useEffect(() => {
    getByName(["altior/device", { ...paramsList }]);
  }, [page, meterType]);
  const handleChange = (event, newValue) => {
    if (newValue !== 2) {
      setValue(newValue);
    }
  };
  useEffect(() => {
    dispatch(setMeterTrashId(null));
  }, []);
  const diameterEnum = [
    { const: "DN6" },
    { const: "DN8" },
    { const: "DN10" },
    { const: "DN15" },
    { const: "DN20" },
    { const: "DN25" },
    { const: "DN32" },
    { const: "DN40" },
    { const: "DN50" },
    { const: "DN65" },
    { const: "DN80" },
    { const: "DN90" },
    { const: "DN100" },
    { const: "DN125" },
    { const: "DN150" },
    { const: "DN200" },
    { const: "DN250" },
    { const: "DN300" },
    { const: "DN350" },
    { const: "DN400" },
    { const: "DN450" },
    { const: "DN500" },
    { const: "DN550" },
    { const: "DN600" },
    { const: "DN650" },
    { const: "DN700" },
    { const: "DN750" },
    { const: "DN800" },
    { const: "DN900" },
    { const: "DN1000" },
    { const: "DN1050" },
    { const: "DN1200" },
    { const: "DN1400" },
    { const: "DN1600" },
    { const: "DN1800" },
    { const: "DN2000" },
    { const: "DN2200" },
    { const: "DN2400" },
    { const: "DN2500" },
    { const: "DN2600" },
    { const: "DN2700" },
  ];
  const notseenEnum = [
    { value: "7", label: t("7_days") },
    { value: "30", label: t("30_days") },
    { value: "90", label: t("90_days") },
  ];

  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      tags: {
        id: 0,
        type: "array",
        url: "tag",
        title: t("Tag"),
        default: paramsList.tags,
        multiple: true,
        properties: {
          tags: {
            type: "number",
          },
        },
      },
      /*     digital_twin_id: {
        id: 1,
        title: t("digital_twin_id"),
        type: "number",
        url: "altior/digital_twin",
        default: paramsList.digital_twin_id,
        properties: {
          class: {
            type: "number",
          },
        },
      }, */
      digital_twin_id: {
        $id: "7",

        mapFn: (result) =>
          result?.data?.data
            ?.map((item) => ({
              key: item.attributes?.id,
              label: `${item.attributes?.name} (${item.attributes?.version})`,
              value: `${item.attributes?.name} (${item.attributes?.version})`,
              tags: item?.attributes?.digital_twin_fields?.tags,
            }))
            ?.filter(
              (item) =>
                item?.tags?.includes("Meter") || item?.tags?.includes("meter")
            ),

        url: "altior/digital_twin",
        type: "string",
        title: t("digital_twin"),
        fromIndex: true,
        dispatched: true,
        checkbox: true,
      },
      serial_number: {
        id: 2,
        title: t("serial_number"),
        type: "string",
        default: paramsList.serial_number,
      },
      mechanical_serial_number: {
        id: 2,
        title: t("metrological_serial"),
        type: "string",
        default: paramsList?.mechanical_serial_number,
      },
      inserted_at: {
        id: 3,
        title: t("configuration_date"),
        type: "object",
        type: "string",
        label: "configuration_date",
        default: paramsList.inserted_at,
      },
      address: {
        id: 4,
        type: "string",
        title: t("street_address"),
        default: paramsList.address,
      },
      pod: {
        id: 5,
        type: "string",
        title: t("pod"),
        default: paramsList.pod,
      },
      diameter: {
        id: 6,
        type: "string",
        title: t("diameter"),
        oneOf: [...diameterEnum],
        default: paramsList.diameter,
      },
      meter_not_seen: {
        type: "string",
        title: t("not_seen"),
        enum: notseenEnum?.map((option) => option.value),
        enumNames: notseenEnum?.map((option) => option.label),
        default: paramsList.meter_not_seen,
      },
      /* in_error: {
        id: 7,
        type: "boolean",
        title: t("in_error"),
        default: paramsList.in_error,
      }, */
    },
  };
  useEffect(() => {
    console.log("mapCoordinates", mapCoordinates);
  }, [mapCoordinates]);
  return (
    <Box sx={{ flexGrow: 1, margin: 0 }}>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h2"
      >
        {t("meters")}
      </Typography>
      <ModalDialog
        open={meterDialogOpen}
        close={() => setMeterDialogOpen(false)}
      >
        <Box>
          In this form it is possible to filter the devices present in the
          registry. All filters are applied in and between them. Description of
          the filters.
          <ul>
            <li>
              <b>Tag:</b> devices are filtered by inserted tag, for example:
              residential_meters Digital Twin: devices are filtered by type of
              meter / sensor / valve
            </li>
            <li>
              <b>Serial:</b> devices are filtered for the serial (S / N) of the
              device, or part of it
            </li>
            <li>
              <b>Start and End Data:</b> the devices are filtered by date of
              insertion into the system
            </li>
            <li>
              <b>Street address:</b> the devices are filtered by the address
              entered
            </li>
            <li>
              <b>POD:</b> devices are filtered by POD{" "}
            </li>
            <li>
              <b>Diameter:</b> the devices are filtered bythe diameter chosen
              from those selectable
            </li>
          </ul>
        </Box>
      </ModalDialog>
      <HelpOutlineIcon
        sx={{ float: "right" /* m: 2  */ }}
        onClick={() => setMeterDialogOpen(true)}
      />

      <FormAccordion onClick={onAccordionClick}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          customValidate={customValidate}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          /*  onSubmit={(values) => {
            onSubmitClick(values.formData);
          }} */
          validator={validator}
          uiSchema={uiSchema}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 1, float: "right" }}
            onClick={() => onSubmitClick(formData)}
          >
            {t("search")}
          </Button>
          <ExportButton
            styleProp={{ float: "right", mr: 2, mt: 1 }}
            paramsList={formData}
          />
        </Form>
      </FormAccordion>

      <Box sx={{ mt: 2 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("table")} />
          <Tab label={t("map")} disabled={!preferences} />
          {selectedClusterId !== "" && (
            <Tab
              label={
                iconHover ? (
                  <CheckCircleOutlineIcon sx={{ fill: "green" }} />
                ) : (
                  <Tooltip title="unset cluster">
                    <HighlightOffIcon sx={{ fill: "red" }} />
                  </Tooltip>
                )
              }
              onMouseEnter={onIconHover}
              onMouseLeave={onIconHover}
              onClick={onCancellClick}
            />
          )}
          {preferenceLoading && (
            <Tab
              label={
                <Tooltip title="saving user preference">
                  <SaveIcon sx={{ fill: "green" }} />
                </Tooltip>
              }
            />
          )}
        </Tabs>
        <TabPanel value={value} index={0}>
          <MetersTableTab
            afs={
              <Table
                data={devices?.data ? devices?.data : resp?.data?.data}
                response={resp}
                perPage={perPage}
                page={page}
                setPage={setPage}
                hideAccordion={hideAccordion}
              />
            }
            heatMeter={
              <Table
                data={devices?.data ? devices?.data : resp?.data?.data}
                response={resp}
                perPage={perPage}
                page={page}
                setPage={setPage}
                hideAccordion={hideAccordion}
              />
            }
            colorMeter={
              <Table
                data={devices?.data ? devices?.data : resp?.data?.data}
                response={resp}
                perPage={perPage}
                page={page}
                setPage={setPage}
                hideAccordion={hideAccordion}
              />
            }
            setMeterType={setMeterType}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapDevice
            pos={mapCoordinates}
            hideAccordion={hideAccordion}
            setPreferenceLoading={setPreferenceLoading}
            params={paramsList}
          />
        </TabPanel>
      </Box>
      <IwdAuthWrapper
        children={
          <Box
            sx={{ display: "flex", justifyContent: "end", padding: "0.5rem" }}
          >
            <Button
              variant="outlined"
              sx={{
                mt: 1,
              }}
              onClick={() => navigate("/meters/create")}
            >
              {t("new_meter")}
            </Button>
          </Box>
        }
        section={"add_meter_btn"}
      />
    </Box>
  );
};

export default React.memo(Meters);
