import { useTranslation } from "react-i18next";
export const nameTransform = (name) => {
  const nameInfo = name?.split(":");
  const firstPart = parseInt(nameInfo[1], 16)?.toString();
  const secondPart = parseInt(nameInfo[2], 16)?.toString();
  if (isNaN(firstPart) || isNaN(secondPart)) {
    return name;
  }
  console.log("part1", typeof firstPart);
  console.log("part2", secondPart);
  const result = `${nameInfo[0]}:${
    firstPart?.length > 1 ? firstPart : "0" + firstPart
  }:${secondPart?.length > 1 ? secondPart : "0" + secondPart}`;
  console.log("namerez", nameInfo);
  return result;
};
export const typeTransform = (type) => {
  const typeLength = type?.toString()?.length;
  const res = typeLength === 1 ? `0${type}` : type;
  return res;
};

export function isMoreThanSecondsAgo(timestamp, seconds) {
  const now = new Date().getTime();
  const differenceInSeconds = (now - timestamp) / 1000;

  return differenceInSeconds >= seconds;
}

export const areArraysEqual = (arr1, arr2) =>
  arr1.length === arr2.length &&
  arr1.every((element, index) => element === arr2[index]);

export function findMaxPosition(objects) {
  let maxPosition = -Infinity;
  let positionFound = false;

  objects.forEach((obj) => {
    const position = parseInt(obj?.attributes?.position);
    if (!isNaN(position) && position > maxPosition) {
      maxPosition = position;
      positionFound = true;
    }
  });

  return positionFound ? maxPosition : 0;
}

const formatDate = (date) => {
  const pad = (num, size) => ("000" + num).slice(size * -1);
  const dateStr =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1, 2) +
    "-" +
    pad(date.getDate(), 2) +
    " " +
    pad(date.getHours(), 2) +
    ":" +
    pad(date.getMinutes(), 2) +
    ":" +
    pad(date.getSeconds(), 2) +
    "." +
    pad(date.getMilliseconds(), 6);
  return dateStr;
};

const parseDate = (dateString) => {
  const [datePart, timePart] = dateString.split(" ");
  const [year, month, day] = datePart.split("-")?.map(Number);
  const [hours, minutes, secondsPart] = timePart.split(":");
  const [seconds, milliseconds] = secondsPart.split(".")?.map(Number);

  return new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds)
  );
};

const createDateRange = (dateStr) => {
  const originalDate = parseDate(dateStr);

  const dateFrom = new Date(originalDate);
  dateFrom.setUTCDate(originalDate.getUTCDate() - 5);

  const dateTo = new Date(originalDate);
  dateTo.setUTCDate(originalDate.getUTCDate() + 5);

  return { from: dateFrom, to: dateTo };
};

export const createQueryString = (dateStr) => {
  const { from, to } = createDateRange(dateStr);

  const fromISO = from.toISOString();
  const toISO = to.toISOString();

  const params = new URLSearchParams({
    from: fromISO,
    to: toISO,
    page: 1,
    per_page: 10,
  });

  return params.toString();
};

export const getMonthStartAndEndUTC = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date string");
  }

  const start = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
  start.setUTCHours(0, 0, 0, 0);

  const end = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0));
  end.setUTCHours(23, 59, 59, 999);

  return {
    startOfMonthUTC: start.toISOString(),
    endOfMonthUTC: end.toISOString(),
  };
};
