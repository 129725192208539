import { useEffect } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DraftsIcon from "@mui/icons-material/Drafts";
import Tooltip from "@mui/material/Tooltip";
import ModalDialog from "../meters/ModalDialog";
import { store } from "../../redux/store";
import CopyTextButton from "./CopyTextButton";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "../../theme";
import {
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { nameTransform, typeTransform } from "../../utils/utilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { setReadingRange } from "../../redux/slices/meterSlice";
function compareDates(a, b) {
  const dateA = new Date(a.attributes.message_date);
  const dateB = new Date(b.attributes.message_date);
  return dateB - dateA;
}
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const ReadedMeters = ({ idFromCondominium }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const gatewayId = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const [message, setMessage] = useState(null);
  const [formData, setFormData] = useState({});
  const [unreadFormData, setUnreadFormData] = useState({});
  const [errorFormData, setErrorFormData] = useState();
  const [unhandledField, setUnhandledField] = useState("");
  const [unhandOrder, setUnhandledOrder] = useState("");
  const [handledField, setHandledField] = useState("");
  const [getErrorMeterMessages] = store.useLazyGetErrorMeterMessagesQuery();
  const [handOrder, setHandledOrder] = useState("");
  const [modalMessage, setModalMessage] = useState(false);

  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [unreadPage, setUnreadPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [errorPage, setErrorPage] = useState(1);
  const [allMessages, setAllMessages] = useState([]);
  const readedMessages = useSelector(
    (state) => state?.gatewaySlice?.readedMessages
  );
  const errorMessages = useSelector(
    (state) => state?.gatewaySlice?.errorMetersMessages
  );
  const unreadedMessages = useSelector(
    (state) => state?.gatewaySlice?.unreadedMessages
  );
  const unreadedMessagesCount = useSelector(
    (state) => state?.gatewaySlice?.unreadedMessagesCount
  );
  const readedMessagesCount = useSelector(
    (state) => state?.gatewaySlice?.readedMessagesCount
  );
  const readedMessagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.readedMessagesFirstRender
  );
  const unreadedMessagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.unreadedMessagesFirstRender
  );
  const errorMessagesCount = useSelector(
    (state) => state?.gatewaySlice?.errorMessagesCount
  );
  const errorMessagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.errorMessagesFirstRender
  );
  const sortedMessagesByData = readedMessages?.slice()?.sort(compareDates);
  const handledMessages = useSelector(
    (state) => state?.gatewaySlice?.handledMeterMessages
  );
  const condominumId = useSelector((state) => state?.gatewaySlice?.gateway)
    ?.fields?.condominium_id;
  const unhandledMeterMesages =
    useSelector((state) => state?.gatewaySlice?.messages)?.filter(
      (item) => item?.attributes?.values?.values?.last_message_type === "data"
    )?.[0]?.attributes?.values?.values?.unhandled_meters ?? [];
  /*     const unhandledMeterMesages =
    useSelector((state) => state?.gatewaySlice?.messages)
      ?.filter
(

        (item) => item?.attributes?.values?.values?.last_message_type === "data"
      )
      ?.filter
(

        (item) => item.attributes?.values?.values?.unhandled_meters.length > 0
      )[0]?.attributes?.values?.values?.unhandled_meters ?? [];   */
  const transformName = (name) => {
    const nameInfo = name.split(":");
    const firstPart = parseInt(nameInfo[1], 16);
    const secondPart = parseInt(nameInfo[2], 16);
    const result = `${nameInfo[0]}:${firstPart}:${secondPart}`;

    return result;
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const transformedHandledMassages =
    handledMessages?.length > 0
      ? handledMessages?.map((obj) => ({
          ...obj,
          attributes: {
            device_id: obj.id,
            handled: true,
            values: {
              class: obj.attributes.class,
              serial_number: obj.attributes.serial_number,
              values: {
                condominium_name: obj.attributes.fields.condominium_name,
                apartment_name: obj.attributes.fields.apartment_name,
              },
            },
          },
        }))
      : [];
  const [getMessages] = store.useLazyGetMeterReadedMessagesQuery();

  const [getUnreadedMessages] = store.useLazyGetMeterUnreadedMessagesQuery();
  useEffect(() => {
    if (value == 0 && readedMessagesFirstRender && gatewayId) {
      getMessages({
        latst_device_seen_from_gateway: gatewayId,
        per_page: perPage,
        page: page,
        ...formData,
      });
    }
  }, [value, readedMessagesFirstRender, gatewayId]);
  useEffect(() => {
    if (value == 0 && !readedMessagesFirstRender && gatewayId) {
      getMessages({
        latst_device_seen_from_gateway: gatewayId,
        per_page: perPage,
        page: page,
        ...formData,
      });
    }
  }, [page]);
  useEffect(() => {
    if (value == 1 && unreadedMessagesFirstRender && gatewayId) {
      getUnreadedMessages({
        latst_device_not_handle_from_gateway: gatewayId,
        page: unreadPage,
        per_page: perPage,
        field: unhandledField,
        order: unhandOrder,
        ...unreadFormData,
      });
    }
  }, [value, unreadedMessagesFirstRender, gatewayId]);
  useEffect(() => {
    if (value == 1 && !unreadedMessagesFirstRender && gatewayId) {
      getUnreadedMessages({
        latst_device_not_handle_from_gateway: gatewayId,
        page: unreadPage,
        per_page: perPage,
        field: unhandledField,
        order: unhandOrder,
        ...unreadFormData,
      });
    }
  }, [unreadPage]);
  useEffect(() => {
    if (value == 2 && errorMessagesFirstRender) {
      getErrorMeterMessages({
        latst_device_seen_from_gateway_with_errors: id,

        page: errorPage,
        per_page: perPage,
        ...errorFormData,
      });
    }
  }, [value, errorMessagesFirstRender]);
  useEffect(() => {
    if (value == 2 && !errorMessagesFirstRender) {
      getErrorMeterMessages({
        latst_device_seen_from_gateway_with_errors: id,

        page: errorPage,
        per_page: perPage,
        ...errorFormData,
      });
    }
  }, [errorPage]);
  useEffect(() => {
    if (readedMessages /* && unhandledMeterMesages && handledMessages */) {
      setAllMessages([
        ...readedMessages,
        // ...transformedHandledMassages,
        // ...unhandledMeterMesages,
      ]);
    }
  }, [readedMessages /* unhandledMeterMesages, handledMessages */]);

  const styleSelector = (params) => {
    console.log("parix", params);
    const datastr = params.row.readTime;
    const date = new Date();
    console.log(date);
    /*     if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    } */
    if (
      params?.row?.conid !== "--" &&
      params?.row?.conid !== condominumId &&
      params?.row?.conid !== ""
    ) {
      return `super-app-theme--Rejected`;
    }
    if (params.row.handled) {
      return `super-app-theme--Blue`;
    }
  };

  useEffect(() => {
    transformName("MAD:50:07");
  }, []);
  const onMailClick = (msg) => {
    setMessage(msg);
    setModalMessage(true);
  };
  const sortUnhandledMsg = (newModel) => {
    setUnhandledField(newModel[0]?.field);
    setUnhandledOrder(newModel[0]?.sort);

    getUnreadedMessages({
      latst_device_not_handle_from_gateway: gatewayId,
      page: unreadPage,
      per_page: perPage,
      field: newModel[0]?.field,
      order: newModel[0]?.sort,
      ...unreadFormData,
    });
  };
  const sortHandledMsg = (newModel) => {
    setHandledField(newModel[0]?.field);
    setHandledOrder(newModel[0]?.sort);
    getMessages({
      latst_device_seen_from_gateway: gatewayId,
      field: newModel[0]?.field,
      order: newModel[0]?.sort,
      ...formData,
    });
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "handled",
      headerName: "handled",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "conid",
      headerName: "conid",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "device",
      headerName: t("Contatore"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "registry",
      headerName: t("registry"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "serial",
      headerName: t("serial_number"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "message_date",
      headerName: t("last_telegram"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },
    /* {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    }, */

    {
      field: "reading",
      headerName: t("reading"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "calories",
      headerName: t("calories"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "frigories",
      headerName: t("frigories"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      sortable: false,
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {params.row.handled ? (
              <>
                {" "}
                {params.value.deviceId && (
                  <IconButton
                    onClick={() =>
                      window.open(`/meters/${params.value.deviceId}`, "_blank")
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                {params.value.frame && (
                  <IconButton onClick={() => onMailClick(params.value)}>
                    <DraftsIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        );
      },
    },
  ];
  const errorColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "handled",
      headerName: "handled",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "conid",
      headerName: "conid",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "device",
      headerName: t("Contatore"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "registry",
      headerName: t("registry"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "serial",
      headerName: t("serial_number"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "message_date",
      headerName: t("last_telegram"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <>
              {params.value.id && (
                <IconButton
                  onClick={() =>
                    window.open(`/meters/${params.value.id}`, "_blank")
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              )}

              {params.value.payload && (
                <IconButton onClick={() => onMailClick(params.value.payload)}>
                  <DraftsIcon />
                </IconButton>
              )}
            </>
          </Box>
        );
      },
    },
  ];
  const renderDevice = (item) => {
    if (item && item.serial) {
      return `${item.manufacturer}:${item.version}:${typeTransform(
        item.type
      )}  ${item?.serial}`;
    } else if (item && item.attributes?.values?.class) {
      return `${item?.attributes?.values?.class}`;
    } else if (item && item.attributes?.values?.values?.value?.type) {
      return `${item?.attributes?.values?.values?.value?.manufacturer}:${
        item?.attributes?.values?.values?.value?.version
      }:${typeTransform(item?.attributes?.values?.values?.value?.type)}`;
    } else {
      return "na";
    }
  };
  const renderErrorDevice = (item) => {
    /*  if (item && item.serial) {
      return `${item.manufacturer}:${item.version}:${typeTransform(
        item.type
      )}  ${item?.serial}`;
    } else if (item && item.attributes?.values?.class) {
      return `${item?.attributes?.values?.class}`;
    } */ if (item && item.attributes?.values?.values?.value?.type) {
      return `${item?.attributes?.values?.values?.value?.manufacturer}:${
        item?.attributes?.values?.values?.value?.version
      }:${typeTransform(item?.attributes?.values?.values?.value?.type)}`;
    } else {
      return "na";
    }
  };

  const renderRegistry = (item) => {
    return item?.attributes &&
      item?.attributes?.values?.values?.condominium_name
      ? `COND ${item?.attributes?.values?.values?.condominium_name} - APP. ${item?.attributes?.values?.values?.apartment_name}`
      : "--";
  };
  const renderUnit = (unit) => {
    if (unit === "J") {
      return "";
    }
    if (unit === "Wh") {
      return "kWh";
    }
    if (unit == "m^3") {
      return "m³";
    }
    return unit;
  };
  const rows = readedMessages?.map((item, i) => ({
    id: i,
    device: renderDevice(item),

    serial:
      item?.attributes?.values?.serial_number ??
      item?.attributes?.values?.serial,
    registry: renderRegistry(item),

    message_date: t("dt_n", { val: new Date(item?.attributes?.message_date) }),

    reading: item?.attributes?.values
      ? `${t("number", {
          val:
            item?.attributes?.values?.values?.value?.last_reading?.value ?? "-",
        })}`
      : "",
    frigories:
      item?.attributes?.values?.values?.value?.cold_last_reading?.value !== null
        ? item?.attributes?.values?.values?.value?.cold_last_reading?.unit ===
          "Wh"
          ? t("number", {
              val:
                item?.attributes?.values?.values?.value?.cold_last_reading
                  ?.value / 1000,
            })
          : t("number", {
              val: item?.attributes?.values?.values?.value?.cold_last_reading
                ?.value,
            })
        : "",
    calories:
      item?.attributes?.values?.values?.value?.heat_last_reading?.value !== null
        ? item?.attributes?.values?.values?.value?.heat_last_reading?.unit ===
          "Wh"
          ? t("number", {
              val:
                item?.attributes?.values?.values?.value?.heat_last_reading
                  ?.value / 1000,
            })
          : t("number", {
              val: item?.attributes?.values?.values?.value?.heat_last_reading
                ?.value,
            })
        : "",
    filtered: item?.attributes?.extra?.values?.value?.filtered,
    unit: renderUnit(
      item?.attributes?.values?.values?.value?.last_reading?.unit ??
        item?.attributes?.values?.values?.value?.heat_last_reading?.unit ??
        item?.attributes?.values?.values?.value?.cold_last_reading?.unit
    ),

    conid: item?.attributes?.values?.values?.condominium_id ?? "--",
    handled: item?.attributes?.values?.values ? true : false,

    actions: item?.attributes
      ? {
          deviceId: item?.attributes?.device_id,
          frame: item?.attributes?.values?.frame,
        }
      : {
          date: t("date_val", { val: new Date(item?.datetime_utc) }),
          frame: item?.attributes?.values?.frame,
        },
  }));

  const errorMsgRows = errorMessages?.map((item, i) => ({
    id: i,
    device: renderErrorDevice(item),

    serial:
      item?.attributes?.values?.values?.value?.address &&
      item?.attributes?.values?.values?.value?.address,
    registry: renderRegistry(item),

    message_date: t("dt_n", { val: new Date(item?.attributes?.message_date) }),

    conid: item?.attributes?.values?.values?.condominium_id ?? "--",
    handled: item?.attributes?.values?.values ? true : false,
    filtered: item?.attributes?.values?.values?.value?.filtered,
    actions: {
      id: item?.attributes?.values?.values?.value?.identifier,
      payload: item?.attributes?.values?.values?.value?.payload,
    },
  }));

  const unreadColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: true,
      flex: 1,
      hide: true,
    },

    {
      field: "device",
      headerName: t("Contatore"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial",
      headerName: t("serial"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "message_date",
      headerName: t("last_telegram"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "filtered",
      headerName: t("filtered"),
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        }
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={() => onMailClick(params?.value)}>
              <DraftsIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const unreadRows = unreadedMessages?.map((item, i) => ({
    id: i,
    device:
      item?.attributes?.values?.values?.value?.manufacturer &&
      item?.attributes?.values?.values?.value?.version &&
      item?.attributes?.values?.values?.value?.type
        ? `${item?.attributes?.values?.values?.value?.manufacturer}:${
            item?.attributes?.values?.values?.value?.version
          }:${typeTransform(item?.attributes?.values?.values?.value?.type)}`
        : "NA",
    message_date: t("dt_n", { val: new Date(item?.attributes?.message_date) }),
    serial: item?.attributes?.values?.values?.value?.address,
    filtered: item?.attributes?.values?.values?.value?.filtered,
    actions: item?.attributes?.values?.values?.value?.payload,
  }));
  useEffect(() => {
    console.log("readedMessages", readedMessages);
  }, [readedMessages]);
  useEffect(() => {
    console.log("mesaj", message);
  }, [message]);

  const unreadedSchema = {
    title: "New Device",
    type: "object",
    properties: {
      serial_number: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial_number"),
      },
      manufacturer: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("manufacturer"),
      },
      meter_type: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("type"),
      },
      version: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("version"),
      },
    },
  };
  const readedSchema = {
    title: "New Device",
    type: "object",
    properties: {
      serial_number: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial_number"),
      },
      class: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("class"),
      },
    },
  };
  const onSubmitClick = () => {
    setUnreadPage(1);
    setPage(1);
    setErrorPage(1);

    if (value == 0) {
      getMessages({
        latst_device_seen_from_gateway: gatewayId,
        field: handledField,
        order: handOrder,
        per_page: perPage,
        page: 1,
        ...formData,
      });
    }
    if (value == 1) {
      getUnreadedMessages({
        latst_device_not_handle_from_gateway: gatewayId,
        page: 1,
        per_page: perPage,
        field: unhandledField,
        order: unhandOrder,
        ...unreadFormData,
      });
    }
    if (value == 2) {
      getErrorMeterMessages({
        latst_device_seen_from_gateway_with_errors: id,

        page: 1,
        per_page: perPage,
        ...errorFormData,
      });
    }
  };
  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };
  const formDataSelector = () => {
    if (value == 0) {
      return formData;
    }
    if (value == 1) {
      return unreadFormData;
    }
    if (value == 2) {
      return errorFormData;
    }
  };
  const formDataChange = (val) => {
    if (value == 0) {
      setFormData(val);
    }
    if (value == 1) {
      setUnreadFormData(val);
    }
    if (value == 2) {
      setErrorFormData(val);
    }
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Accordion sx={{ mt: 2, mb: 2 }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Search form
          </AccordionSummary>
          <AccordionDetails>
            <Form
              schema={value == 0 ? readedSchema : unreadedSchema}
              formData={formDataSelector()}
              onChange={(changeEvent) => formDataChange(changeEvent.formData)}
              onSubmit={(values) => onSubmitClick(values.formData)}
              validator={validator}
              uiSchema={uiSchm}
              /* widgets={widgets} */
              showErrorList={false}
              /*   customValidate={customValidate} */
              noHtml5Validate
            />
          </AccordionDetails>
        </Accordion>
        <ModalDialog
          open={modalMessage}
          close={() => setModalMessage(false)}
          title={"Message"}
        >
          <Box>
            <Typography variant="subtitle2">
              <pre
                style={{
                  overflow: "auto",
                  background: "#c0c0c0",

                  padding: "9px",
                }}
              >
                {JSON.stringify(message, null, 10)}
              </pre>
            </Typography>
            <CopyTextButton text={message?.frame ?? message} />
          </Box>
        </ModalDialog>
        <Box /* sx={{ borderBottom: 1, borderColor: "divider" }} */>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("handled_meters")} />
            <Tab label={t("unhandled_meters")} />
            <Tab label={t("error_meters")} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {readedMessages?.length > 0 && (
            <Box sx={{ height: "60vh", width: "99%" }}>
              <StyledDataGrid
                getRowClassName={(params) => styleSelector(params)}
                disableColumnMenu
                rows={rows}
                columns={columns}
                rowCount={readedMessagesCount}
                pageSize={perPage}
                onSortModelChange={sortHandledMsg}
                sortingMode="server"
                paginationMode="server"
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
              />
            </Box>
          )}
          {readedMessages?.length === 0 && (
            <Box sx={{ mt: 7 }}>
              <Typography
                sx={{ color: theme.palette.primary.main }}
                variant="h3"
                align="center"
              >
                {t("no_available_data")}
              </Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {unreadedMessages?.length > 0 && (
            <Box sx={{ height: "60vh", width: "99%" }}>
              <StyledDataGrid
                /*   getRowClassName={(params) => styleSelector(params)} */
                disableColumnMenu
                rows={unreadRows}
                columns={unreadColumns}
                rowCount={unreadedMessagesCount}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                onSortModelChange={sortUnhandledMsg}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setUnreadPage(pageNumber + 1);
                }}
                page={unreadPage - 1}
                paginationMode="server"
                sortingMode="server"
              />
            </Box>
          )}
          {unreadedMessages?.length === 0 && (
            <Box sx={{ mt: 7 }}>
              <Typography
                sx={{ color: theme.palette.primary.main }}
                variant="h3"
                align="center"
              >
                {t("no_available_data")}
              </Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {errorMessages?.length > 0 && (
            <Box sx={{ height: "60vh", width: "99%" }}>
              <StyledDataGrid
                /*     getRowClassName={(params) => styleSelector(params)} */
                disableColumnMenu
                rows={errorMsgRows}
                columns={errorColumns}
                rowCount={errorMessagesCount}
                pageSize={perPage}
                /*        onSortModelChange={sortHandledMsg} */
                sortingMode="server"
                paginationMode="server"
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setErrorPage(pageNumber + 1);
                }}
                page={errorPage - 1}
              />
            </Box>
          )}
          {errorMessages?.length === 0 && (
            <Box sx={{ mt: 7 }}>
              <Typography
                sx={{ color: theme.palette.primary.main }}
                variant="h3"
                align="center"
              >
                {t("no_available_data")}
              </Typography>
            </Box>
          )}
        </TabPanel>
      </Box>
    </>
  );
};
export default ReadedMeters;
